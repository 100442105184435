<template>
  <v-form>
    <div class="text-end ma-4">
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <CreateCenter class="mr-3" />
        </div>
      </v-card-actions>
    </div>
    <v-container class="mt-10">
      <div class="text-start">
        <v-btn @click="getLocation" rounded color="primary" dark>
          Use this Location
          <v-progress-circular
            v-if="Loading"
            indeterminate
            color="white"
            class="ma-1"
            :size="20"
            :width="3"
          ></v-progress-circular>
        </v-btn>
      </div>
      <v-progress-linear
        class="ma-2"
        indeterminate
        color="info"
        v-if="TableLoading"
      ></v-progress-linear>
      <v-row class="pa-3">
        <v-col cols="12" sm="5">
          <vuetify-google-autocomplete
            id="map"
            classname="form-control"
            placeholder="Fulfillment Center Address"
            v-on:placechanged="setPickupLocation"
            country="ng"
            shaped
            filled
            :value="FulfillmentCenter.locationName"
            autocomplete="off"
          >
          </vuetify-google-autocomplete>
          <v-row>
            <v-col cols="12" sm="6">
              <v-checkbox
                v-model="FulfillmentCenter.bikeService"
                label="Bike Service"
                color="info"
                hide-details
                disabled
              ></v-checkbox>
              <v-checkbox
                v-model="FulfillmentCenter.carService"
                label="Car Service"
                color="info"
                hide-details
                disabled
              ></v-checkbox>
              <v-checkbox
                v-model="FulfillmentCenter.vanService"
                label="Van Service"
                color="info"
                hide-details
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox
                v-model="FulfillmentCenter.truckService"
                label="Truck Service"
                color="info"
                hide-details
                disabled
              ></v-checkbox>
              <v-checkbox
                v-model="FulfillmentCenter.planeService"
                label="Plane Service"
                color="info"
                hide-details
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>
          <div class="text-center ma-2 mb-5">
            <ActivateOrDeactivateDeliveryType
              :FulfillmentCenter="FulfillmentCenter"
            />
          </div>
          <v-select
            class="mt-2"
            :items="FulfillmentCenter.weight"
            filled
            item-text="name"
            shaped
            label="Select Weight Measurement"
          ></v-select>
          <v-text-field
            :label="`Price Per Weight`"
            filled
            shaped
            v-model="FulfillmentCenter.weight[0].value"
          ></v-text-field>
          <v-text-field
            :label="`Weight Min Price`"
            filled
            shaped
            v-model="FulfillmentCenter.weight[0].minPrice"
          ></v-text-field>
          <v-text-field
            v-model="FulfillmentCenter.returnPercentageFees"
            label="Return Percentage Fees %"
            filled
            shaped
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="7">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Bike Setup
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Car Setup
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3">
                Van Setup
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 4" step="4">
                Truck Setup
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5"> Plane Setup </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12 pa-2" min-height="200px">
                  <v-text-field
                    v-model="FulfillmentCenter.bikeKmCharge"
                    label="Bike KmCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.bikeMinCharge"
                    label="Bike MinCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.bikeMinKM"
                    label="Bike Min KM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.bikeMaxKM"
                    label="Bike MaxKM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.bikeReduceChargesAfterKm"
                    label="Bike ReduceChargesAfter Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.bikeReduceFeePerKm"
                    label="Bike ReduceFeePer Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-checkbox v-model="FulfillmentCenter.bikeFlat">
                        <template v-slot:label>
                          <div>Activate Bike Flat Rate</div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="FulfillmentCenter.bikeFlatFees"
                        label="Flat Rate Fees"
                        filled
                        shaped
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>
                </v-card-actions>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-12 pa-2" min-height="200px">
                  <v-text-field
                    v-model="FulfillmentCenter.carKmCharge"
                    label="Car KmCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.carMinCharge"
                    label="Car MinCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.carMinKM"
                    label="Car Min KM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.carMaxKM"
                    label="Car MaxKM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.carReduceChargesAfterKm"
                    label="Car ReduceChargesAfter Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.carReduceFeePerKm"
                    label="Car ReduceFeePer Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-checkbox v-model="FulfillmentCenter.carFlat">
                        <template v-slot:label>
                          <div>Activate Bike Flat Rate</div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="FulfillmentCenter.carFlatFees"
                        label="Flat Rate Fees"
                        filled
                        shaped
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="e1 = 1"> Back </v-btn>

                  <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>
                </v-card-actions>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card class="mb-12 pa-2" min-height="200px">
                  <v-text-field
                    v-model="FulfillmentCenter.vanKmCharge"
                    label="Van KmCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.vanMinCharge"
                    label="Van MinCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.vanMinKM"
                    label="Van Min KM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.vanMaxKM"
                    label="Van MaxKM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.vanReduceChargesAfterKm"
                    label="Van ReduceChargesAfter Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.vanReduceFeePerKm"
                    label="Van ReduceFeePer Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-checkbox v-model="FulfillmentCenter.vanFlat">
                        <template v-slot:label>
                          <div>Activate Bike Flat Rate</div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="FulfillmentCenter.vanFlatFees"
                        label="Flat Rate Fees"
                        filled
                        shaped
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="e1 = 2"> Back </v-btn>

                  <v-btn color="primary" @click="e1 = 4"> Continue </v-btn>
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card class="mb-12 pa-2" min-height="200px">
                  <v-text-field
                    v-model="FulfillmentCenter.truckKmCharge"
                    label="Truck KmCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.truckMinCharge"
                    label="Truck MinCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.truckMinKM"
                    label="Truck Min KM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.truckMaxKM"
                    label="Truck MaxKM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.truckReduceChargesAfterKm"
                    label="Truck ReduceChargesAfter Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.truckReduceFeePerKm"
                    label="Truck ReduceFeePer Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-checkbox v-model="FulfillmentCenter.vanFlat">
                        <template v-slot:label>
                          <div>Activate Bike Flat Rate</div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="FulfillmentCenter.vanFlatFees"
                        label="Flat Rate Fees"
                        filled
                        shaped
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="e1 = 3"> Back </v-btn>

                  <v-btn color="primary" @click="e1 = 5"> Continue </v-btn>
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="5">
                <v-card class="mb-12 pa-2" min-height="200px">
                  <v-text-field
                    v-model="FulfillmentCenter.planeKmCharge"
                    label="Plane KmCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.planeMinCharge"
                    label="Plane MinCharge"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.planeMinKM"
                    label="Plane Min KM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.planeMaxKM"
                    label="Plane MaxKM"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.planeReduceChargesAfterKm"
                    label="Plane ReduceChargesAfter Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-text-field
                    v-model="FulfillmentCenter.planeReduceFeePerKm"
                    label="Plane ReduceFeePer Km"
                    filled
                    shaped
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-checkbox v-model="FulfillmentCenter.planeFlat">
                        <template v-slot:label>
                          <div>Activate Bike Flat Rate</div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="FulfillmentCenter.planeFlatFees"
                        label="Flat Rate Fees"
                        filled
                        shaped
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="e1 = 4"> Back </v-btn>

                  <v-btn color="primary" @click="e1 = 1"> Continue </v-btn>
                </v-card-actions>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-btn @click="updateFulfillmentCenter()" rounded color="primary" dark>
          Update Center
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import CreateCenter from "../components/CreateFulfillmentCenter.vue";
import ActivateOrDeactivateDeliveryType from "../components/ActivateOrDeactivateDeliveryTypes.vue";

export default {
  components: {
    CreateCenter,
    ActivateOrDeactivateDeliveryType,
  },
  data() {
    return {
      e1: 1,
      edit: false,
      select: "",
      selectedValue: "",
      weight: [],
      Loading: false,
    };
  },
  methods: {
    getLocation() {
      this.Loading = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.setPickupLocation);
      } else {
        this.Loading = false;
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "geolocation not available");
      }
    },
    setPickupLocation(payload) {
      this.Loading = false;
      if (payload.coords) {
        if (payload.coords.longitude) {
          this.FulfillmentCenter.longitude = payload.coords.longitude;
        }
        if (payload.coords.latitude) {
          this.FulfillmentCenter.latitude = payload.coords.latitude;
        }
        if (payload.coords.name) {
          this.FulfillmentCenter.locationName = payload.coords.name;
        }
      } else {
        if (payload.longitude) {
          this.FulfillmentCenter.longitude = payload.longitude;
        }
        if (payload.latitude) {
          this.FulfillmentCenter.latitude = payload.latitude;
        }
        if (payload.name) {
          this.FulfillmentCenter.locationName = payload.name;
        }
      }
    },

    getFulfillmentCenter() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("getFulfillmentCenter", payload);
    },

    updateFulfillmentCenter() {
      let payload = {
        token: this.getToken,
        ...this.FulfillmentCenter,
      };
      this.$store.dispatch("updateFulfillmentCenter", payload);
    },
  },

  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    FulfillmentCenter() {
      return this.$store.getters.FulfillmentCenter;
    },
  },
  created() {
    this.getFulfillmentCenter();
  },

  watch: {
    select(val) {
      const newObj = new Object();
      let arrObj = [];
      newObj.name = val;
      newObj.value = this.selectedValue;
      newObj.minPrice = this.minPrice;
      arrObj.push(newObj);
      this.weight = arrObj;
    },
    selectedValue(val) {
      const newObj = new Object();
      let arrObj = [];
      newObj.name = this.select;
      newObj.value = val;
      newObj.minPrice = this.minPrice;
      arrObj.push(newObj);
      this.weight = arrObj;
    },
    minPrice(val) {
      const newObj = new Object();
      let arrObj = [];
      newObj.name = this.select;
      newObj.value = this.selectedValue;
      newObj.minPrice = val;
      arrObj.push(newObj);
      this.weight = arrObj;
    },
  },
};
</script>