<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on">
          Create Fulfillment Center
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Create New Fulfillment Center</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-form>
          <v-container class="mt-10">
            <div class="text-end">
              <v-btn @click="getLocation" rounded color="primary" dark>
                Use this Location
                <v-progress-circular
                  v-if="Loading"
                  indeterminate
                  color="white"
                  class="ma-1"
                  :size="20"
                  :width="3"
                ></v-progress-circular>
              </v-btn>
            </div>
            <v-row class="pa-3">
              <v-col cols="12" sm="5">
                <vuetify-google-autocomplete
                  id="map"
                  classname="form-control"
                  placeholder="Fulfillment Center Address"
                  v-on:placechanged="setPickupLocation"
                  country="ng"
                  shaped
                  filled
                  autocomplete="off"
                >
                </vuetify-google-autocomplete>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-checkbox
                      v-model="bikeService"
                      label="Bike Service"
                      color="info"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="carService"
                      label="Car Service"
                      color="info"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="vanService"
                      label="Van Service"
                      color="info"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-checkbox
                      v-model="truckService"
                      label="Truck Service"
                      color="info"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="planeService"
                      label="Plane Service"
                      color="info"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-select
                  class="mt-2"
                  :items="[
                    'Tonne',
                    'Kilogram',
                    'Gram',
                    'Milligram',
                    'Microgram',
                    'Imperial Ton',
                    'US Ton',
                    'Stone',
                    'Pound',
                    'Ounce',
                  ]"
                  filled
                  v-model="select"
                  shaped
                  label="Select Weight Measurement"
                ></v-select>
                <v-text-field
                  type="number"
                  v-if="select"
                  :label="`Price Per ${select}`"
                  filled
                  shaped
                  v-model="selectedValue"
                ></v-text-field>
                <v-text-field
                  type="number"
                  v-if="select"
                  :label="`Min Price For ${select}`"
                  filled
                  shaped
                  v-model="minPrice"
                ></v-text-field>
                <v-text-field
                  v-model="returnPercentageFees"
                  label="Return Percentage Fees %"
                  filled
                  shaped
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="7">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">
                      Bike Setup
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">
                      Car Setup
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 3" step="3">
                      Van Setup
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 4" step="4">
                      Truck Setup
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="5"> Plane Setup </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card class="mb-12 pa-2" min-height="200px">
                        <v-text-field
                          v-model="bikeKmCharge"
                          label="Bike KmCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="bikeMinCharge"
                          label="Bike MinCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="bikeMinKM"
                          label="Bike Min KM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="bikeMaxKM"
                          label="Bike MaxKM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="bikeReduceChargesAfterKm"
                          label="Bike ReduceChargesAfter Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="bikeReduceFeePerKm"
                          label="Bike ReduceFeePer Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-checkbox v-model="bikeFlat">
                              <template v-slot:label>
                                <div>Activate Bike Flat Rate</div>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="bikeFlatFees"
                              label="Flat Rate Fees"
                              filled
                              shaped
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="e1 = 2">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card class="mb-12 pa-2" min-height="200px">
                        <v-text-field
                          v-model="carKmCharge"
                          label="Car KmCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="carMinCharge"
                          label="Car MinCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="carMinKM"
                          label="Car Min KM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="carMaxKM"
                          label="Car MaxKM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="carReduceChargesAfterKm"
                          label="Car ReduceChargesAfter Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="carReduceFeePerKm"
                          label="Car ReduceFeePer Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-checkbox v-model="carFlat">
                              <template v-slot:label>
                                <div>Activate Bike Flat Rate</div>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="carFlatFees"
                              label="Flat Rate Fees"
                              filled
                              shaped
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="e1 = 1" text> Back </v-btn>
                        <v-btn color="primary" @click="e1 = 3">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card class="mb-12 pa-2" min-height="200px">
                        <v-text-field
                          v-model="vanKmCharge"
                          label="Van KmCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="vanMinCharge"
                          label="Van MinCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="vanMinKM"
                          label="Van Min KM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="vanMaxKM"
                          label="Van MaxKM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="vanReduceChargesAfterKm"
                          label="Van ReduceChargesAfter Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="vanReduceFeePerKm"
                          label="Van ReduceFeePer Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-checkbox v-model="vanFlat">
                              <template v-slot:label>
                                <div>Activate Bike Flat Rate</div>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="vanFlatFees"
                              label="Flat Rate Fees"
                              filled
                              shaped
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="e1 = 2" text> Back </v-btn>
                        <v-btn color="primary" @click="e1 = 4">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <v-card class="mb-12 pa-2" min-height="200px">
                        <v-text-field
                          v-model="truckKmCharge"
                          label="Truck KmCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="truckMinCharge"
                          label="Truck MinCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="truckMinKM"
                          label="Truck Min KM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="truckMaxKM"
                          label="Truck MaxKM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="truckReduceChargesAfterKm"
                          label="Truck ReduceChargesAfter Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="truckReduceFeePerKm"
                          label="Truck ReduceFeePer Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-checkbox v-model="truckFlat">
                              <template v-slot:label>
                                <div>Activate Bike Flat Rate</div>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="truckFlatFees"
                              label="Flat Rate Fees"
                              filled
                              shaped
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="e1 = 3" text> Back </v-btn>
                        <v-btn color="primary" @click="e1 = 5">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="5">
                      <v-card class="mb-12 pa-2" min-height="200px">
                        <v-text-field
                          v-model="planeKmCharge"
                          label="Plane KmCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="planeMinCharge"
                          label="Plane MinCharge"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="planeMinKM"
                          label="Plane Min KM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="planeMaxKM"
                          label="Plane MaxKM"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="planeReduceChargesAfterKm"
                          label="Plane ReduceChargesAfter Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-text-field
                          v-model="planeReduceFeePerKm"
                          label="Plane ReduceFeePer Km"
                          filled
                          shaped
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-checkbox v-model="planeFlat">
                              <template v-slot:label>
                                <div>Activate Bike Flat Rate</div>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="planeFlatFees"
                              label="Flat Rate Fees"
                              filled
                              shaped
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="e1 = 4"> Back </v-btn>
                        <v-btn color="primary" @click="e1 = 1">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
            <div class="text-center mt-4">
              <v-btn @click="createFulfillmentCenter" color="pink" dark>
                Add New Fulfillment Center
              </v-btn>
            </div>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      e1: 1,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      select: "",
      selectedValue: "",
      weight: [],
      locationName: "",
      Loading: false,
      latitude: "",
      longitude: "",
      minPrice: "",
      vanKmCharge: "",
      vanMinCharge: "",
      vanMinKM: "",
      vanMaxKM: "",
      vanReduceChargesAfterKm: "",
      vanReduceFeePerKm: "",
      bikeKmCharge: "",
      bikeMinCharge: "",
      bikeMinKM: "",
      bikeMaxKM: "",
      bikeReduceChargesAfterKm: "",
      bikeReduceFeePerKm: "",
      carKmCharge: "",
      carMinCharge: "",
      carMinKM: "",
      carMaxKM: "",
      carReduceChargesAfterKm: "",
      carReduceFeePerKm: "",
      truckKmCharge: "",
      truckMinCharge: "",
      truckMinKM: "",
      truckMaxKM: "",
      truckReduceChargesAfterKm: "",
      truckReduceFeePerKm: "",
      planeKmCharge: "",
      planeMinCharge: "",
      planeMinKM: "",
      planeMaxKM: "",
      planeReduceChargesAfterKm: "",
      planeReduceFeePerKm: "",
      platformPercentageCut: "",
      vanService: false,
      bikeService: true,
      carService: true,
      truckService: false,
      planeService: false,
      returnPercentageFees: "",
      bikeFlat: false,
      bikeFlatFees: "",
      carFlat: false,
      carFlatFees: "",
      vanFlat: false,
      vanFlatFees: "",
      truckFlat: false,
      truckFlatFees: "",
      planeFlat: false,
      planeFlatFees: "",
      bikePlatformPercentageCut: "",
      carPlatformPercentageCut: "",
      vanPlatformPercentageCut: "",
      truckPlatformPercentageCut: "",
      planePlatformPercentageCut: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    select(val) {
      const newObj = new Object();
      let arrObj = [];
      newObj.name = val;
      newObj.value = this.selectedValue;
      newObj.minPrice = this.minPrice;
      arrObj.push(newObj);
      this.weight = arrObj;
    },
    selectedValue(val) {
      const newObj = new Object();
      let arrObj = [];
      newObj.name = this.select;
      newObj.value = val;
      newObj.minPrice = this.minPrice;
      arrObj.push(newObj);
      this.weight = arrObj;
    },
    minPrice(val) {
      const newObj = new Object();
      let arrObj = [];
      newObj.name = this.select;
      newObj.value = this.selectedValue;
      newObj.minPrice = val;
      arrObj.push(newObj);
      this.weight = arrObj;
    },
  },
  methods: {
    getLocation() {
      this.Loading = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.setPickupLocation);
      } else {
        this.Loading = false;
        console.log("No geolocation");
      }
    },
    setPickupLocation(payload) {
      console.log(payload, "OP==");
      this.Loading = false;
      if (payload.coords) {
        if (payload.coords.longitude) {
          this.longitude = payload.coords.longitude;
        }
        if (payload.coords.latitude) {
          this.latitude = payload.coords.latitude;
        }
        if (payload.coords.name) {
          this.locationName = payload.coords.name;
        }
      }
      if (payload.longitude) {
        this.longitude = payload.longitude;
      }

      if (payload.latitude) {
        this.latitude = payload.latitude;
      }

      if (payload.name) {
        this.locationName = payload.name;
      }
    },
    createFulfillmentCenter() {
      if (this.locationName == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "fill all required fields");
        return;
      }
      if (this.vanService) {
        if (
          !this.vanKmCharge ||
          !this.vanMinCharge ||
          !this.vanMaxKM ||
          !this.vanMinKM ||
          !this.vanReduceChargesAfterKm ||
          !this.vanReduceFeePerKm
        ) {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "the following field are required because of vanService activated vanKmCharge,vanMinCharge,vanMaxKM,vanReduceChargesAfterKm and vanReduceFeePerKm"
          );
          return;
        }
      }
      if (this.bikeService) {
        if (
          !this.bikeKmCharge ||
          !this.bikeMinCharge ||
          !this.bikeMaxKM ||
          !this.bikeMinKM ||
          !this.bikeReduceChargesAfterKm ||
          !this.bikeReduceFeePerKm
        ) {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "the following field are required because of bikeService activated bikeKmCharge,bikeMinCharge,bikeMaxKM,bikeReduceChargesAfterKm and bikeReduceFeePerKm"
          );
          return;
        }
      }

      if (this.carService) {
        if (
          !this.carKmCharge ||
          !this.carMinCharge ||
          !this.carMaxKM ||
          !this.carMinKM ||
          !this.carReduceChargesAfterKm ||
          !this.carReduceFeePerKm
        ) {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "the following field are required because of carService activated carKmCharge,carMinCharge,carMaxKM,carReduceChargesAfterKm and carReduceFeePerKm"
          );
          return;
        }
      }

      if (this.truckService) {
        if (
          !this.truckKmCharge ||
          !this.truckMinCharge ||
          !this.truckMaxKM ||
          !this.truckMinKM ||
          !this.truckReduceChargesAfterKm ||
          !this.truckReduceFeePerKm
        ) {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "the following field are required because of truckService activated truckKmCharge,truckMinCharge,truckMaxKM,truckReduceChargesAfterKm and truckReduceFeePerKm"
          );
          return;
        }
      }

      if (this.planeService) {
        if (
          !this.planeKmCharge ||
          !this.planeMinCharge ||
          !this.planeMaxKM ||
          !this.planeMinKM ||
          !this.planeReduceChargesAfterKm ||
          !this.planeReduceFeePerKm
        ) {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "the following field are required because of planeService activated planeKmCharge,planeMinCharge,planeMaxKM,planeReduceChargesAfterKm and planeReduceFeePerKm"
          );
          return;
        }
      }

      let payload = {
        token: this.getToken,
        locationName: this.locationName,
        weight: this.weight,
        latitude: this.latitude,
        longitude: this.longitude,
        vanService: this.vanService,
        vanKmCharge: this.vanKmCharge,
        vanMinCharge: this.vanMinCharge,
        vanMinKM: this.vanMinKM,
        vanMaxKM: this.vanMaxKM,
        vanReduceChargesAfterKm: this.vanReduceChargesAfterKm,
        vanReduceFeePerKm: this.vanReduceFeePerKm,
        bikeService: this.bikeService,
        bikeKmCharge: this.bikeKmCharge,
        bikeMinCharge: this.bikeMinCharge,
        bikeMinKM: this.bikeMinKM,
        bikeMaxKM: this.bikeMaxKM,
        bikeReduceChargesAfterKm: this.bikeReduceChargesAfterKm,
        bikeReduceFeePerKm: this.bikeReduceFeePerKm,
        carService: this.carService,
        carKmCharge: this.carKmCharge,
        carMinCharge: this.carMinCharge,
        carMinKM: this.carMinKM,
        carMaxKM: this.carMaxKM,
        carReduceChargesAfterKm: this.carReduceChargesAfterKm,
        carReduceFeePerKm: this.carReduceFeePerKm,
        truckService: this.truckService,
        truckKmCharge: this.truckKmCharge,
        truckMinCharge: this.truckMinCharge,
        truckMinKM: this.truckMinKM,
        truckMaxKM: this.truckMaxKM,
        truckReduceChargesAfterKm: this.truckReduceChargesAfterKm,
        truckReduceFeePerKm: this.truckReduceFeePerKm,
        planeService: this.planeService,
        planeKmCharge: this.planeKmCharge,
        planeMinCharge: this.planeMinCharge,
        planeMinKM: this.planeMinKM,
        planeMaxKM: this.planeMaxKM,
        planeReduceChargesAfterKm: this.planeReduceChargesAfterKm,
        planeReduceFeePerKm: this.planeReduceFeePerKm,
        platformPercentageCut: this.platformPercentageCut,
        returnPercentageFees: this.returnPercentageFees,
        route: this.$router,
      };
      this.$store.dispatch("CreateFulfillmentCenter", payload);
      this.dialog = false;
    },
  },
};
</script>