<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="490">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" dark v-bind="attrs" v-on="on">
          Activate/Deactivate
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Activate/Deactivate </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-checkbox
                v-model="bikeService"
                label="Bike Service"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="carService"
                label="Car Service"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="vanService"
                label="Van Service"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="truckService"
                label="Truck Service"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="planeService"
                label="Plane Service"
                color="info"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox
                v-model="bikeFlat"
                label="Bike Flat Rate"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="carFlat"
                label="Car Flat Rate"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="vanFlat"
                label="Van Flat Rate"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="truckFlat"
                label="Truck Flat Rate"
                color="info"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="planeFlat"
                label="Plane Flat Rate"
                color="info"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            close
          </v-btn>
          <v-btn color="pink" @click="ActivateOrDeactivateDeliveryType()">
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["FulfillmentCenter"],
  data() {
    return {
      dialog: false,
      vanService: false,
      bikeService: false,
      carService: false,
      truckService: false,
      planeService: false,
      vanFlat: false,
      bikeFlat: false,
      carFlat: false,
      truckFlat: false,
      planeFlat: false,
    };
  },
  methods: {
    ActivateOrDeactivateDeliveryType() {
      let payload = {
        token: this.getToken,
        vanService: this.vanService,
        bikeService: this.bikeService,
        carService: this.carService,
        truckService: this.truckService,
        planeService: this.planeService,
        vanFlat: this.vanFlat,
        bikeFlat: this.bikeFlat,
        carFlat: this.carFlat,
        truckFlat: this.truckFlat,
        planeFlat: this.planeFlat,
      };
      this.$store.dispatch("ActivateOrDeactivateDeliveryType", payload);
      this.dialog = false;
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.vanService = this.FulfillmentCenter.vanService;
        this.bikeService = this.FulfillmentCenter.bikeService;
        this.carService = this.FulfillmentCenter.carService;
        this.truckService = this.FulfillmentCenter.truckService;
        this.planeService = this.FulfillmentCenter.planeService;

        this.vanFlat = this.FulfillmentCenter.vanFlat;
        this.bikeFlat = this.FulfillmentCenter.bikeFlat;
        this.carFlat = this.FulfillmentCenter.carFlat;
        this.truckFlat = this.FulfillmentCenter.truckFlat;
        this.planeFlat = this.FulfillmentCenter.planeFlat;
      }
    },
  },
};
</script>